const config = {
  "broker": "3",
  "name": "English",
  "registrationBundle": "englisheu",
  "defaultCountryName": "Ireland",
  "defaultCountryCode": "IE",
  "fxgeoip_code": "IE",
  languageCode: 'en_eu',
  languageName:'English',
  spokenLanguageCode:'en',
  direction:'ltr',
  footerDisclaimer:'893',
  brand: 'iforex'
};
module.exports = config;